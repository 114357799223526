import City from "../images/resim1.jpg";
import City2 from "../images/resim2.jpg";
import City3 from "../images/resim3.jpg";
import City4 from "../images/resim4.jpg";
import City5 from "../images/resim5.jpg";
import City6 from "../images/resim6.jpg";
import City7 from "../images/resim7.jpg";
import City8 from "../images/resim8.jpg";
import City9 from "../images/resim9.jpg";
import City10 from "../images/resim10.jpg";
import City11 from "../images/resim11.jpg";


 export const images =[
    {title:"Odun Ateşi", subtitle:"Kayseri",src:City},
    {title:"Sonbahar Yaprakları", subtitle:"Kayseri",src:City2},
    {title:"Sonbahar Yaprakları", subtitle:"Kayseri",src:City3},
    {title:"Açık Mavi Gökyüzü Altında Çıplak Ağaç Tepelerinin", subtitle:"Kayseri",src:City4},
    {title:"Üzgün ​​Danbo", subtitle:"Kayseri",src:City5},
    {title:"Kutu Adam Rubik Küpü", subtitle:"Rubik Küpü,Kutu Adam",src:City6},
    {title:"Rubik Küpü", subtitle:"Rubik Küpü,Kutu Adam",src:City7},
    {title:"Kutu Adam", subtitle:"Kayseri",src:City8},
    {title:"Kutu Adam", subtitle:"Kayseri",src:City9},
    {title:"Epika", subtitle:"Kayseri",src:City10},
    {title:"Güneşli Sonbahar", subtitle:"Kayseri",src:City11},

 ];