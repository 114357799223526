import React, {useState} from 'react';
import {images} from "../Helpers/CarouselData";

function Carousel() {

 	return (<div className=" px-4 py-4 mx-auto">
   <div className="columns-2 md:columns-3 lg:columns-4 gap-6">
   
    {images.map((item, index)=>{
      
      return (
      <div className="pics relative" key={index}>
      <img className="h-auto max-w-full rounded-lg mb-6 shadow-xl" src={item.src} />
      <div className="text-base font-medium mb-0 absolute left-0 bottom-0 text-white bg-slate-900/75 p-2">{item.title}</div>
      </div>

      	)


    })}
    </div>
 	</div>
 	)

}

export default Carousel