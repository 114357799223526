import logo from "../images/mustafa-ocal-logo.png";

export default function Header() {
    return (
        <>
           <header class="text-gray-600 body-font">
  <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
      <img src={logo} alt="Mustafa Öçal" />
     
    </a>
    <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center flex flex-col lg:flex-row space-y-6 pt-8 lg:pt-0 lg:space-y-0 lg:space-x-8 text-2xl font-bold lg:font-normal lg:text-xl">
      <a href="#" class="text-black hover:text-red-600 lg:border-0 lg:border-b-2 lg:border-red-600">anasayfa</a>

      <a href="#" class="text-black hover:text-red-600">hakkımda</a>
      <a href="#" class="text-black hover:text-red-600">blog</a>
      <a href="#" class="text-black hover:text-red-600">fotoğraflar</a>
      <a href="#" class="text-black hover:text-red-600">videolar</a>
      <a href="#" class="text-black hover:text-red-600">iletişim</a>

  

    </nav>
   
  </div>
</header>
        </>
    )
}