import React, { Component  } from 'react'

class MyLeaderBoardAd extends Component {

    componentDidMount() {
     (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
    return(
        <div>
        <ins className = "adsbygoogle"
                style = { {display:"inline-block",width:"728px",height:"90px"} }
                data-ad-client = "ca-pub-4786429194704202"
                data-ad-slot = "3027203439"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
        </div>)
    }
}

export default MyLeaderBoardAd