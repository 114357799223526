import logo from './logo.svg';
import './index.css';
import Carousel from './components/Carousel';
import Header from './components/Header';
import Footer from './components/Footer';
import MyLeaderBoardAd from "./components/Ads";

function App() {
  return <div className="app bg-slate-50">
    <Header />
    <Carousel />
    <MyLeaderBoardAd />

    <Footer />
  </div>;
}

export default App;
