export default function Footer() {

    return (
        <>
        <footer className="flex-shrink-0 text-gray-600 bg-cover mt-10 bg-top bg-no-repeat bg-gray-900/75">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-wrap md:text-left text-center order-first justify-space-evenly">
       
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font text-xl font-bold text-gray-200 tracking-widest text-sm mb-3">En Çok Aranan Resimleri</h2>
        <nav className="list-none mb-10">
          <li>
            <a className="text-gray-300 hover:text-gray-400">Kutu Adam</a>
          </li>
          <li>
            <a className="text-gray-300 hover:text-gray-400">Kayseri</a>
          </li>
          <li>
            <a className="text-gray-300 hover:text-gray-400">Manzara</a>
          </li>
          <li>
            <a className="text-gray-300 hover:text-gray-400">Sonbahar</a>
          </li>
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">        
      <h2 className="title-font text-xl font-bold text-gray-200 tracking-widest text-sm mb-3">En İyi Resimler</h2>
        <nav className="list-none mb-10">
          <li>
            <a className="text-gray-300 hover:text-gray-400">Ürgüp</a>
          </li>
          <li>
            <a className="text-gray-300 hover:text-gray-400">Kayseri</a>
          </li>
          <li>
            <a className="text-gray-300 hover:text-gray-400">Kutu Adam</a>
          </li>
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-bold text-xl text-gray-200 tracking-widest text-sm mb-3">Son Yüklenen Resimler</h2>
        <nav className="list-none mb-10">
          <li>
            <a className="text-gray-300 hover:text-gray-400">Ürgüp</a>
          </li>
          <li>
            <a className="text-gray-300 hover:text-gray-400">Kayseri</a>
          </li>
          <li>
            <a className="text-gray-300 hover:text-gray-400">Mersin</a>
          </li>
          <li>
            <a className="text-gray-300 hover:text-gray-800">Atatürk</a>
          </li>
        </nav>
      </div><div className="lg:w-1/4 md:w-1/2 w-full px-4">
      <h2 className="title-font font-medium text-gray-400 tracking-widest text-md mb-3">Bültenimize Katılın</h2>
        <div className="flex xl:flex-nowrap md:flex-nowrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start">
          <div className="relative w-40 sm:w-auto xl:mr-4 lg:mr-0 sm:mr-4 mr-2">
      
   <input name="eposta" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>

          </div>
          <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-gray-50 bg-yellow-500 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded">Gönder</button>
        </div>
       
      </div>
    </div>
  </div>
  <div className="bg-gray-200">
    <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
      <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-10 h-10 text-gray-800 p-2 bg-none" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M6.092 7c-.054-.355-.09-.685-.092-1-.008-1.338.445-2.362 1.235-3.404 1.073-1.418 2.758-2.594 4.765-2.596 2.008.002 3.692 1.178 4.766 2.596.79 1.042 1.243 2.066 1.234 3.404-.012 1.92-1.071 3.88-2.108 5.584-.987-1.233-1.981-2.626-2.747-3.951.245-.481.456-.951.625-1.405.652-1.759.068-2.237-1.77-2.228-1.838-.009-2.422.469-1.769 2.228 1.528 4.119 6.619 9.744 9.769 12.772l-2 5c-3.17-3.229-11.246-12.619-11.908-17m1.77 7.948c.938 1.323 1.832 2.484 2.837 3.762-1.765 2.147-3.49 4.059-4.699 5.29l-2-5c.977-.939 2.393-2.383 3.862-4.052"/></svg>
        <span className="ml-1 text-xl">Mustafa Öçal</span>
      </a>
      <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">© 2023-2024 All right reserved
      </p>
      <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
   
        <a className="ml-3 text-gray-500" href="https://twitter.com/Mustafa__Ocal" target="_blank">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a className="ml-3 text-gray-500" href="https://www.instagram.com/mustafa__ocal/" target="_blank">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
        </a>
        <a className="ml-3 text-gray-500" href="https://www.linkedin.com/in/mustafaocal/" target="_blank">
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
  </div>
</footer>
        </>
    )
}